import React from 'react';
import ReactDOM from 'react-dom/client';
import {createBrowserRouter, redirect, RouterProvider} from "react-router-dom";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { ptBR } from 'date-fns/locale/pt-BR';
import { HelmetProvider } from 'react-helmet-async';

import './index.css'

import WebFont from 'webfontloader';

WebFont.load({
    google: {
        families: ['Roboto:300,400,500,700']
    }
});

// LOGIN ROUTES ---------------------------------------------------------------------------------------------------
const loginRoutes = [
    { index: true,              lazy: () => import("./routes/login/login") },
    { path: "forgotpassword",   lazy: () => import("./routes/login/forgotpassword") },
    { path: "logout",           lazy: () => import("./routes/login/logout") },
    { path: "sessionexpired",   lazy: () => import("./routes/login/sessionexpired") },
    { path: "usernotadmin",     lazy: () => import("./routes/login/usernotadmin") },
]

// ADMINISTRATIVE ROUTES ---------------------------------------------------------------------------------------------------
const administrativeRoutes = [
    { index: true,              lazy: () => import("./routes/console/administrative/administrativeIndex") },
    {
        path: "admins", lazy: () => import("./routes/console/administrative/admins/listAdmins"),
        children: [
            { path: "create",    lazy: () => import("./routes/console/administrative/admins/createAdmin") },
            { path: ":adminID",  lazy: () => import("./routes/console/administrative/admins/editAdmin") },
        ]
    },
    {
        path: "companies",
        lazy: () => import("./routes/console/administrative/companies/listCompanies"),
        children: [
            { path: "create",    lazy: () => import("./routes/console/administrative/companies/createCompany") },
            { path: ":companyID",  lazy: () => import("./routes/console/administrative/companies/editCompany") },
        ]
    },
]

// ACCOUNTING ROUTES ---------------------------------------------------------------------------------------------------
const accountingRoutes = [
    { index: true,              lazy: () => import("./routes/console/accounting/accountingIndex") },
    {
        path: "originatingSystems",
        lazy: () => import("./routes/console/accounting/originatingSystems/listOriginatingSystems"),
        children: [
            { path: "create",               lazy: () => import("./routes/console/accounting/originatingSystems/createOriginatingSystem") },
            { path: ":originatingSystemID", lazy: () => import("./routes/console/accounting/originatingSystems/editOriginatingSystem") },
        ]
    },
    {
        path: "companyEventTypes",
        lazy: () => import("./routes/console/accounting/companyEventTypes/listCompanyEventTypes"),
        children: [
            { path: "create",       lazy: () => import("./routes/console/accounting/companyEventTypes/createCompanyEventType") },
            { path: ":eventTypeID", lazy: () => import("./routes/console/accounting/companyEventTypes/editCompanyEventType") },
        ]
    },
    {
        path: "companyAccounts",
        lazy: () => import("./routes/console/accounting/companyAccounts/listCompanyAccounts"),
        children: [
            { path: "create",     lazy: () => import("./routes/console/accounting/companyAccounts/createCompanyAccount") },
            { path: ":accountID", lazy: () => import("./routes/console/accounting/companyAccounts/editCompanyAccount") },
        ]
    },
    {
        path: "legalbankEventTypes",
        lazy: () => import("./routes/console/accounting/legalbankEventTypes/listLegalbankEventTypes"),
        children: [
            { path: "create",       lazy: () => import("./routes/console/accounting/legalbankEventTypes/createLegalbankEventType") },
            { path: ":eventTypeID", lazy: () => import("./routes/console/accounting/legalbankEventTypes/editLegalbankEventType") },
        ]
    },
    {
        path: "legalbankAccounts",
        lazy: () => import("./routes/console/accounting/legalbankAccounts/listLegalbankAccounts"),
        children: [
            { path: "create",     lazy: () => import("./routes/console/accounting/legalbankAccounts/createLegalbankAccount") },
            { path: ":accountID", lazy: () => import("./routes/console/accounting/legalbankAccounts/editLegalbankAccount") },
        ]
    },
    {
        path: "translationRules",
        lazy: () => import("./routes/console/accounting/translationRules/listTranslationRules"),
        children: [
            { path: "create",             lazy: () => import("./routes/console/accounting/translationRules/createTranslationRule") },
            {
                path: ":translationRuleID",
                lazy: () => import("./routes/console/accounting/translationRules/editTranslationRule"),
                children: [
                    { path: "createRuleItem", lazy: () => import("./routes/console/accounting/translationRules/translationRuleItems/createTranslationRuleItem") },
                ]
            },
        ]
    },
    {
        path: "accountingEntries",
        lazy: () => import("./routes/console/accounting/accountingEntries/listAccountingEntries"),
    },
]

// COMPANY ROUTES ---------------------------------------------------------------------------------------------------
const companyRoutes = [

    { path: ":companyID?",       lazy: () => import("./routes/console/company/activeCompany/setActiveCompany") },

    {
        path: ":companyID/accountingEntries",
        lazy: () => import("./routes/console/company/accountingEntries/listAccountingEntries"),
    },

    {
        path: ":companyID/users",
        lazy: () => import("./routes/console/company/users/listUsers"),
        children: [
            { path: "create",  lazy: () => import("./routes/console/company/users/createUser") },
            { path: ":userID", lazy: () => import("./routes/console/company/users/editUser") },
        ]
    },
    {
        path: ":companyID/customers",
        lazy: () => import("./routes/console/company/customers/listCustomers"),
        children: [
            { path: "create",      lazy: () => import("./routes/console/company/customers/createCustomer") },
            { path: ":customerID", lazy: () => import("./routes/console/company/customers/editCustomer") },
        ]
    },

    {
        path: ":companyID/customers/:customerID/billingaccounts",
        lazy: () => import("./routes/console/company/customers/billingAccounts/listBillingAccounts"),
        children: [
            { path: "create",                                               lazy: () => import("./routes/console/company/customers/billingAccounts/createBillingAccount") },
            { path: ":billingAccountID",                                    lazy: () => import("./routes/console/company/customers/billingAccounts/editBillingAccount") },
            { path: ":billingAccountID/charges/create",                     lazy: () => import("./routes/console/company/customers/billingAccounts/charges/createCharge") },
            { path: ":billingAccountID/invoices/create",                    lazy: () => import("./routes/console/company/customers/billingAccounts/invoices/createInvoice") },
            {
                path: ":billingAccountID/invoices/:invoiceID",
                lazy: () => import("./routes/console/company/customers/billingAccounts/invoices/editInvoice"),
                children: [
                    { path: "createBoleto",       lazy: () => import("./routes/console/company/customers/billingAccounts/invoices/boletos/createBoleto") },
                    { path: "createInvoiceEvent", lazy: () => import("./routes/console/company/customers/billingAccounts/invoices/invoiceEvents/createInvoiceEvent") },
                ]
            },
        ]
    },

]

const router = createBrowserRouter([
    {
        path: "/",
        lazy: () => import("./routes/root"),

        children: [
            { index: true,              loader: () => redirect("/console") },
            {
                path: "login",
                children: loginRoutes,
            },

            {
                path: "console",
                lazy: () => import("./routes/console/consoleRoot"),
                children: [
                    {
                        lazy: () => import("./routes/console/consoleError"),
                        children: [
                            { index: true, lazy: () => import("./routes/console/consoleIndex") },

                            {
                                path: "administrative",
                                lazy: () => import("./routes/console/administrative/administrativeRoot"),
                                children: administrativeRoutes,
                            },

                            {
                                path: "accounting",
                                lazy: () => import("./routes/console/accounting/accountingRoot"),
                                children: accountingRoutes,
                            },

                            {
                                path: "company",
                                lazy: () => import("./routes/console/company/companyRoot"),
                                children: companyRoutes,
                            },
                        ],
                    },
                ],
            },
        ],
    }
]);

const helmetContext = {};

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <HelmetProvider context={helmetContext}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
                <RouterProvider router={router}/>
            </LocalizationProvider>
        </HelmetProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
